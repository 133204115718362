import PropTypes from 'prop-types';
import { Grid, Typography, IconButton } from '@mui/material';
import { useTheme } from '@mui/system';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { IconCircleMinus } from '@tabler/icons-react';
import InitiativeMemberBoxImageWithAvatar from '../InitiativeMemberBoxImageWithAvatar';
import messages from '../messages';
import { useDeleteInitiativeMemberMutation, useUpdateInitiativesMutation } from 'store/rtk-query/initiativesApi';
import { colors } from 'utils/constants/colors';
import { useDeleteOrganizationAuthorityMutation } from 'store/rtk-query/inviteeApi';

export const NetworkDetailBox = ({
  userName,
  currentPosition,
  memberUserId,
  showRemoveButton,
  profileImage,
  keywords,
  showOrganizationName,
  administratorsArr,
  ownerUserId,
  onNetworkDetailClick
}) => {
  const theme = useTheme();
  const intl = useIntl();
  const { initiativeId } = useParams();

  const [deleteMemberUserOfInitiative] = useDeleteInitiativeMemberMutation();
  const [deleteOrganizationAuthority] = useDeleteOrganizationAuthorityMutation();
  const [updateInitiatives] = useUpdateInitiativesMutation();

  const handleAdministratorRemove = () => {
    updateInitiatives({
      body: {
        administrators: administratorsArr.filter((x) => x.uuid !== memberUserId).map((item) => ({ uuid: item.uuid }))
      },
      userId: ownerUserId,
      initiativeId
    });
  };

  return (
    <Grid
      container
      sx={{
        transition: 'background-color 0.3s ease-in-out',
        ':hover': {
          background: theme.palette.primary.main,
          '& .MuiTypography-root': {
            color: theme.palette.background.default
          },
          '& svg': {
            transition: 'background-color 0.8s ease-in-out',
            background: theme.palette.primary.main,
            color: theme.palette.grey[400]
          },
          '.count-text': {
            backgroundColor: theme.palette.background.default
          },
          cursor: 'pointer'
        },
        cursor: 'pointer'
      }}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
      spacing={1}
      pr={0.5}
      pl={2}
      onClick={onNetworkDetailClick}
    >
      <Grid item sm={3} xs={8}>
        <InitiativeMemberBoxImageWithAvatar label1={userName} label2={showOrganizationName} avatarSrc={profileImage} />
      </Grid>
      <Grid item sm={showRemoveButton ? 8 : 9} xs={4}>
        <Typography fontSize={'1rem'} textTransform={'capitalize'} variant="h6">
          {currentPosition === 'Enterprise' ? intl.formatMessage(messages.Keywords) : intl.formatMessage(messages.Expertise)}
        </Typography>
        <Typography variant="h6" fontWeight={400} fontSize={'0.9rem'} color={colors.dark[550]}>
          {keywords?.length > 0 ? keywords.map(({ title }) => title).join(', ') : intl.formatMessage(messages.NoExpertiseAvailable)}
        </Typography>
      </Grid>
      {showRemoveButton && (
        <Grid item xs={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              return currentPosition === 'Administrator'
                ? handleAdministratorRemove()
                : currentPosition === 'Enterprise'
                ? deleteOrganizationAuthority({ initiativeId, inviteeUserId: memberUserId })
                : deleteMemberUserOfInitiative({ initiativeId, memberUserId });
            }}
            color="error.main"
          >
            <IconCircleMinus color={colors.error[450]} height={'1.5rem'} width={'1.5rem'} />
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

NetworkDetailBox.propTypes = {
  userName: PropTypes.string,
  currentPosition: PropTypes.string,
  memberUserId: PropTypes.string,
  showRemoveButton: PropTypes.bool,
  profileImage: PropTypes.string,
  keywords: PropTypes.array,
  showOrganizationName: PropTypes.string,
  administratorsArr: PropTypes.array,
  ownerUserId: PropTypes.string,
  onNetworkDetailClick: PropTypes.func
};
