import { useEffect, useState, cloneElement } from 'react';
import { Avatar, Box, IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { colors } from 'utils/constants/colors';

const ITEM_HEIGHT = 55;
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  border: 'none',
  textTransform: 'capitalize',
  padding: '4px',
  gap: 4,
  alignItems: 'center',
  color: colors.dark[550],
  borderBottom: `1px solid ${theme.palette.primary.light}`,
  borderRight: `1px solid ${theme.palette.primary.light}`,
  borderLeft: `1px solid ${theme.palette.primary.light}`,
  '&:first-of-type': {
    borderTop: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '3px 3px 0px 0px'
  },
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '0px 0px 3px 3px'
  },
  background: theme.palette.background.default,
  '&:hover': {
    background: theme.palette.secondary.cardBackground
  }
}));
export default function MenuIconComponent({
  options,
  anchorEl,
  open,
  handleClick,
  handleClose,
  id: initiativeId = '',
  renderIcon,
  sx,
  width,
  height,
  customIconButton = false,
  customAnchorPositionTop = 18,
  customAnchorPositionLeft = 17,
  popperWidth = '30ch',
  iconClassName = '',
  disableMaxHeight = true
}) {
  const [anchorPosition, setAnchorPosition] = useState({ top: 0, left: 0 });

  useEffect(() => {
    if (anchorEl) {
      const anchorRect = anchorEl.getBoundingClientRect();
      setAnchorPosition({
        top: anchorRect.top + customAnchorPositionTop,
        left: anchorRect.left + customAnchorPositionLeft
      });
    }
  }, [anchorEl, customAnchorPositionTop, customAnchorPositionLeft]);

  return (
    <>
      <Box height={height} width={width} sx={sx} className={iconClassName} display={'flex'} alignItems={'flex-end'}>
        <IconButton
          sx={{
            cursor: 'pointer',
            height: {
              xs: '30px',
              msm: 'auto'
            },
            ...(customIconButton && { backgroundColor: colors.info[30], borderRadius: '8px', ml: '6px' })
          }}
          onClick={handleClick}
          aria-label="MenuIcon"
        >
          {renderIcon && cloneElement(renderIcon)}
        </IconButton>
      </Box>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button'
        }}
        anchorEl={anchorEl}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: disableMaxHeight && ITEM_HEIGHT * 4.5,
            width: popperWidth,
            background: 'transparent',
            boxShadow: 'none'
          }
        }}
        sx={{ width: '400px' }}
      >
        {options &&
          options.map(({ id, title, icon, onClick, avatarSrc, className = '' }) => (
            <StyledMenuItem slot={initiativeId} key={id} onClick={onClick} className={className}>
              {!icon && <Avatar src={avatarSrc} sx={{ height: '1.5rem', width: '1.5rem', mx: '0.5rem' }} />}
              {icon && icon}
              {title && title}
            </StyledMenuItem>
          ))}
      </Menu>
    </>
  );
}

MenuIconComponent.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  options: PropTypes.array,
  anchorEl: PropTypes.node,
  open: PropTypes.bool,
  handleClick: PropTypes.func,
  handleClose: PropTypes.func,
  renderIcon: PropTypes.node,
  sx: PropTypes.object,
  width: PropTypes.number,
  height: PropTypes.number,
  customIconButton: PropTypes.bool,
  customAnchorPositionTop: PropTypes.number,
  customAnchorPositionLeft: PropTypes.number,
  popperWidth: PropTypes.string,
  iconClassName: PropTypes.string,
  disableMaxHeight: PropTypes.bool
};
