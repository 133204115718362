export const privacyItemsObj = {
  PUBLIC: 'Public',
  RESTRICTED: 'Restricted'
};

export const privacyItems = [privacyItemsObj.PUBLIC, privacyItemsObj.RESTRICTED];

export const visiblityItemsObj = {
  PUBLIC: 'Public',
  PRIVATE: 'Private',
  PLATFORM_MEMBERS: 'Platform_members'
};

export const visibilityItems = [visiblityItemsObj.PUBLIC, visiblityItemsObj.PLATFORM_MEMBERS, visiblityItemsObj.PRIVATE];

export const initiativeStates = {
  ARCHIVED: 'Archived',
  PUBLISHED: 'Published',
  DRAFTED: 'Draft'
};

export const initiativeStateArr = [initiativeStates.PUBLISHED, initiativeStates.DRAFTED, initiativeStates.ARCHIVED];

export const tabsConstant = {
  DETAIL_TAB: 'details',
  ACTIVITIES_TAB: 'activities',
  NEWS_TAB: 'contributions',
  LITERATURE_TAB: 'literature',
  NETWORK_TAB: 'network',
  SIMILAR_INITIATIVE_TAB: 'similar-initiative',
  LINKED_INITIATIVE_TAB: 'linked-initiative',
  TRAINING_TAB: 'training'
};

export const searchTabsConstant = {
  RELATED_TO_ME: 'Related to me',
  RELATED_TO_INITIATIVE: 'Related to initiative',
  RELATED_TO_NETWORK: 'Related to network',
  RELATED_TO_KEYWORDS: 'Related to keywords'
};

export const initiativeTabs = [
  { tabId: 1, value: tabsConstant.DETAIL_TAB, disabled: false },
  { tabId: 2, value: tabsConstant.ACTIVITIES_TAB, disabled: false },
  { tabId: 3, value: tabsConstant.NEWS_TAB, disabled: false },
  { tabId: 4, value: tabsConstant.LITERATURE_TAB, disabled: true },
  { tabId: 5, value: tabsConstant.NETWORK_TAB, disabled: false },
  { tabId: 6, value: tabsConstant.LINKED_INITIATIVE_TAB, disabled: false },
  { tabId: 7, value: tabsConstant.TRAINING_TAB, disabled: true }
];

export const reactionType = {
  LIKE: 'like',
  DISLIKE: 'dislike'
};

export const ROLE_LABEL = {
  ADMINISTRATOR: 'Administrator',
  MEMBER: 'Member',
  ENTERPRISES: 'Enterprise'
};

export const ACTIVITY_NAME = {
  CONTRIBUTIONS: 'contributions',
  NEWS: 'news',
  INITIATIVE: 'initiative',
  USER: 'user',
  NETWORK: 'network',
  LITERATURE: 'literature',
  TRAINING: 'training',
  Initiative_INVITEE: 'initiative_invitee',
  Linked_Initiative: 'linked_initiative',
  Initiative_MEMBER: 'initiative_member',
  ORGANIZATION_AUTHORITY: 'organization_authority',
  COMMENT: 'comment',
  REACTION: 'reaction'
};

export const INITIATIVE_TYPE = {
  ALL: '',
  WATCHED: 'watched',
  SELF: 'self',
  RELATED_TO_ME: 'related',
  OTHERS: 'others'
};

export const SORT_TYPE = {
  ALL: 'All',
  MATCHING_SCORE: 'Matching Score',
  CREATION_DATE: 'Creation Date',
  MODIFICATION_DATE: 'Modification Date',
  TITLE: 'Title'
};

export const ASCENDING_DESCENDING_TYPE = {
  A_TO_Z: 'Ascending',
  Z_TO_A: 'Descending'
};

export const MY_INITIATIVE_USER_TYPE = {
  ADMINISTRATOR: 'Administrator',
  OWNER: 'Owner',
  MEMBER: 'Member',
  All: ''
};

export const My_INITIATIVES = 'My Initiatives';
export const WATCHED_INITIATIVES = 'Watched Initiatives';
export const MATCHED_INITIATIVES = 'most matching initiatives';
export const MATCHED_NETWORK = 'most matching network';
